import { Editor, EditorContent } from "@tiptap/react";
import { FormInputWrapper, useFormWrapper } from "@withjuly/solis";
import { useId } from "react";

export interface InlineRichTextProps {
	name: string;

	label?: string;
	help?: string;

	editor: Editor | null;
	right?: React.ReactNode;
}

export const InlineRichText: React.FC<InlineRichTextProps> = ({
	name,
	label,
	help,
	editor,
	right,
}) => {
	const id = useId();
	const { error } = useFormWrapper(name);

	const describedByIds: string[] = [];
	if (help) {
		describedByIds.push(id + "-help");
	}
	if (error?.message) {
		describedByIds.push(id + "-error");
	}

	return (
		<FormInputWrapper id={id} name={name} label={label} help={help}>
			<div className="relative">
				<EditorContent
					className="focus-within:shadow-20 w-full rounded-lg border border-gray-200 bg-gray-600 p-3 text-sm outline-none focus-within:border-blue-500 [&>div]:outline-none [&>div]:ring-0"
					editor={editor}
				/>
				{right}
			</div>
		</FormInputWrapper>
	);
};
