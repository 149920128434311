import { templateVariables } from "@withjuly/fabric";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
	Text,
} from "@withjuly/solis";
import { Lightning } from "@withjuly/julycons";
import { InlineRichText } from "~/components/Input/RichText/InlineRichText";
import { Editor } from "@tiptap/react";

interface RichSubjectInputProps {
	editor: Editor | null;
}

export const RichSubjectInput: React.FC<RichSubjectInputProps> = ({
	editor,
}) => {
	return (
		<InlineRichText
			editor={editor}
			name="subject"
			label="Subject"
			right={
				<div className="absolute right-3 top-0 flex h-full items-center">
					<DropdownMenu>
						<DropdownMenuTrigger asChild>
							<div>
								<Lightning className="h-full w-auto cursor-pointer rounded bg-blue-800 p-1 text-blue-300 transition-all hover:bg-blue-700" />
							</div>
						</DropdownMenuTrigger>
						<DropdownMenuContent
							className="origin-top-right border-gray-200 bg-gray-500"
							align="end"
						>
							{Object.entries(templateVariables).map(([name, variable]) => {
								return (
									<DropdownMenuItem
										className="mx-1 my-0.5 flex cursor-pointer items-center gap-2 rounded transition-all duration-75 hover:bg-blue-700"
										onClick={() => {
											editor?.commands.insertContent(
												`<span data-type="july-template-variable" data-name="${name}"/>`,
											);
											editor?.commands.insertContent(" ");
										}}
										key={name}
									>
										<Lightning className="h-4 w-4 bg-blue-700 text-blue-300" />
										<Text>{variable?.displayName ?? name}</Text>
									</DropdownMenuItem>
								);
							})}
						</DropdownMenuContent>
					</DropdownMenu>
				</div>
			}
		/>
	);
};
