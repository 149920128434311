import { Link } from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import Underline from "@tiptap/extension-underline";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { TemplateVariables } from "~/utils/editor/extensions/TemplateVariables";

export const convertSubjectToHtml = (text: string) => {
	return text.replace(
		/{{(.+?)}}/g,
		(_, variableName) =>
			`<span data-type="july-template-variable" data-name="${variableName}">${variableName}</span>`,
	);
};

export const useSubjectEditor = (
	subject?: string,
	props?: { editable?: boolean; onUpdate?: (text: string) => void },
) => {
	return useEditor({
		extensions: [
			StarterKit,
			TemplateVariables,
			Placeholder.configure({ placeholder: "Subject" }),
		],
		content: convertSubjectToHtml(subject ?? ""),
		...props,
		onUpdate: ({ editor }) => props?.onUpdate?.(editor.getText()),
	});
};

export const useBodyEditor = (
	body?: string,
	props?: { editable?: boolean; onUpdate?: (text: string) => void },
) => {
	return useEditor({
		extensions: [
			StarterKit,
			TemplateVariables,
			Link,
			Underline,
			Placeholder.configure({
				placeholder: "Write a message...",
			}),
		],
		content: body,
		...props,
		onUpdate: ({ editor }) => props?.onUpdate?.(editor.getHTML()),
	});
};

export const placeholderSubject =
	"Lorem ipsum dolor sit amet, consectetur adipiscing";

export const placeholderBody = `Hi {{creator_name}} sit,

<p></p>

<p>consectetur adipiscing elit. Phasellus ultricies, neque sed dignissim efficitur, ligula velit vulputate leo, quis posuere diam eros vitae sapien. Curabitur sed auctor erat.</p> 

<p></p>

Curabitur eget sem eget lorem feugiat imperdiet. Praesent vel elementum metus. Fusce nunc augue, rutrum a purus nec, vestibulum sodales lorem. Sed at felis efficitur, pulvinar felis in, elementum diam. Suspendisse sit amet dapibus augue. Aliquam et tincidunt lorem. Nulla facilisi. Maecenas suscipit condimentum sagittis. 

<p></p>

Nulla id accumsan metus. Aliquam condimentum, purus et bibendum tristique, mi mi vestibulum sem, vel blandit lorem lacus a nulla. 

<p></p>

Fusce fermentum pellentesque feugiat. Vestibulum eleifend accumsan massa nec efficitur. Ut consectetur iaculis neque vel tristique. Nulla quis interdum justo.`;
