import { useWindowSize } from "@withjuly/frontend-common";
import { deviceWidth } from "@withjuly/solis";

export const useBreakpoint = <T>(
	data: Partial<Record<keyof typeof deviceWidth | "base", T>>,
) => {
	const window = useWindowSize();

	if (window.width === undefined) {
		return data.base;
	}

	if (window.width < deviceWidth.sm) {
		return data.sm ?? data.base;
	}

	if (window.width < deviceWidth.md) {
		return data.md ?? data.sm ?? data.base;
	}

	if (window.width < deviceWidth.lg) {
		return data.lg ?? data.md ?? data.sm ?? data.base;
	}

	if (window.width < deviceWidth.xl) {
		return data.xl ?? data.lg ?? data.md ?? data.sm ?? data.base;
	}

	return data["2xl"] ?? data.xl ?? data.lg ?? data.md ?? data.sm ?? data.base;
};
