import {
	NodeViewWrapper,
	Node,
	NodeViewProps,
	ReactNodeViewRenderer,
	mergeAttributes,
} from "@tiptap/react";
import { TemplateVariableData, templateVariables } from "@withjuly/fabric";
import { cx } from "@withjuly/frontend-common";
import { ArrowRight, Lightning } from "@withjuly/julycons";
import { Text, Tooltip, TooltipTrigger, TooltipContent } from "@withjuly/solis";
import { trpc } from "~/components/Utility/trpc";

export const TemplateVariables = Node.create({
	name: "template-variable",

	atom: true,
	inline: true,
	selectable: false,

	group: "inline",

	addAttributes() {
		return {
			name: {
				default: null,
				parseHTML: (element) => element.getAttribute("data-name"),
				renderHTML: (attributes) => ({
					"data-name": attributes.name,
				}),
			},
		};
	},

	parseHTML() {
		return [
			{
				tag: `span[data-type="july-${this.name}"]`,
			},
		];
	},

	addNodeView() {
		return ReactNodeViewRenderer(TemplateVariableNodeView);
	},

	renderHTML({ HTMLAttributes, node }) {
		return [
			"span",
			mergeAttributes({ "data-type": `july-${this.name}` }, HTMLAttributes),
			node.attrs.name,
		];
	},

	renderText({ node }) {
		return `{{${node.attrs.name}}}`;
	},
});

const TemplateVariableNodeView = ({ node, editor, getPos }: NodeViewProps) => {
	const { data: variables } = trpc.template.variables.useQuery();

	const name = node.attrs.name as keyof typeof templateVariables;
	const variable = templateVariables[name] as TemplateVariableData;
	if (!variable) {
		return;
	}

	const displayName = templateVariables[name]?.displayName ?? name;
	const variableValue =
		variables?.[name] || templateVariables[name]?.defaultValue || displayName;

	return (
		<NodeViewWrapper as="span">
			<span contentEditable={false}>
				<Tooltip>
					<TooltipTrigger asChild>
						<span
							className="relative"
							onClick={() =>
								editor
									.chain()
									.focus(getPos() + 1)
									.run()
							}
						>
							<TemplateVariable value={displayName} />
						</span>
					</TooltipTrigger>
					<TooltipContent
						side="bottom"
						className="max-w-[480px] p-4"
						sideOffset={0}
					>
						<div className="flex flex-col gap-3">
							<Text className="gap-1">
								The <TemplateVariable value={displayName} className="mx-1" />{" "}
								variable {variable.description}
							</Text>
							<div className="flex items-center justify-center gap-2 rounded bg-gray-400 p-4">
								<span className="w-full">
									{variable.example.before}
									<TemplateVariable value={displayName} />
									{variable.example.after ?? ""}
								</span>
								<ArrowRight className="h-10 w-10" />
								<span className="w-full">
									{variable.example.before}
									{variableValue}
									{variable.example.after ?? ""}
								</span>
							</div>
						</div>
					</TooltipContent>
				</Tooltip>
			</span>
		</NodeViewWrapper>
	);
};

const TemplateVariable = ({
	value,
	className,
}: {
	value: string;
	className?: string;
}) => {
	return (
		<span
			className={cx(
				"m-0.25 inline-flex cursor-default gap-[2px] rounded border-blue-700 bg-blue-700 px-1 text-blue-300",
				className,
			)}
		>
			<Lightning className="my-auto h-[12px] w-[12px]" />
			<span>{value}</span>
		</span>
	);
};
