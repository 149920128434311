import { Editor, EditorContent } from "@tiptap/react";
import { DefaultTemplate, EmailTemplate } from "@withjuly/fabric";
import { cx } from "@withjuly/frontend-common";
import { Close } from "@withjuly/julycons";
import { Button, Heading, Text } from "@withjuly/solis";
import { useState } from "react";
import { UpgradeModal } from "~/components/Payment/UpgradeModal";
import {
	convertSubjectToHtml,
	useBodyEditor,
	useSubjectEditor,
	placeholderBody,
	placeholderSubject,
} from "~/components/Templates/utils";
import { trpc } from "~/components/Utility/trpc";
import { useBreakpoint } from "~/utils/hooks/breakpoint";

interface ChooseTemplatePaneProps {
	setDefaultTemplate: (t: DefaultTemplate) => void;
	setIsOpen: (isOpen: boolean) => void;
	isForAutomaticPitching?: boolean;
}

export const ChooseTemplatePane: React.FC<ChooseTemplatePaneProps> = ({
	setDefaultTemplate,
	setIsOpen,
	isForAutomaticPitching,
}) => {
	const [selectedTemplate, setSelectedTemplate] = useState<DefaultTemplate>();
	const subjectEditor = useSubjectEditor(undefined, { editable: false });
	const bodyEditor = useBodyEditor(undefined, { editable: false });

	const setTemplate = (template: DefaultTemplate) => {
		if (template.requiresUpgrade) {
			subjectEditor?.commands.setContent(
				convertSubjectToHtml(placeholderSubject),
			);
			bodyEditor?.commands.setContent(placeholderBody);
		} else {
			subjectEditor?.commands.setContent(
				convertSubjectToHtml(template.subject ?? ""),
			);
			bodyEditor?.commands.setContent(template.body ?? "");
		}
		setSelectedTemplate(template);
	};

	const { data: defaultTemplates } = trpc.template.getDefault.useQuery(
		undefined,
		{
			onSuccess: (data) => {
				const firstDefaultTemplate = data.sections[0]?.templates?.[0];
				if (selectedTemplate || !firstDefaultTemplate) {
					return;
				}
				setTemplate(firstDefaultTemplate);
			},
		},
	);
	const { data: templates } = trpc.template.getAll.useQuery(undefined, {
		onSuccess: (data) => {
			if (selectedTemplate || !data.templates[0]) {
				return selectedTemplate;
			}
			setTemplate(buildDefaultTemplateFromTemplate(data.templates[0]));
		},
	});

	const buildDefaultTemplateFromTemplate = (
		template: EmailTemplate,
	): DefaultTemplate => {
		return {
			subject: template.subject,
			body: template.body,
			emoji: template.emoji ?? "☀️",
			name: template.name,
			section: "Your Templates",
			description: "",
			requiresUpgrade: false,
			order: -1,
			uuid: template.uuid,
			attachments: template.attachments,
		};
	};

	const isMobile = useBreakpoint({ base: true, lg: false }) ?? false;

	return (
		<div className="shadow-100 flex h-screen w-full max-w-[800px] overflow-hidden rounded-2xl border border-gray-300 md:min-w-[800px]">
			<div className="flex w-full flex-col gap-6 bg-gray-500 p-8 md:w-[256px] md:min-w-[256px]">
				<Heading variant="h5">Choose a template</Heading>

				<Close
					className="absolute right-6 top-6 h-4 w-4 cursor-pointer text-gray-200 transition-all hover:text-gray-100 md:hidden"
					role="button"
					aria-label="Close"
					tabIndex={0}
					onClick={() => setIsOpen(false)}
				/>

				{/* Don't use a template */}
				{!isForAutomaticPitching ? (
					<Button
						variant="outline"
						size="huge"
						full
						onClick={() =>
							setDefaultTemplate({
								subject: "",
								uuid: "",
								body: "",
								description: "",
								emoji: "☀️",
								name: "",
								order: -1,
								requiresUpgrade: false,
								section: "",
								attachments: [],
							})
						}
					>
						✍️ Start from scratch
					</Button>
				) : null}
				<div className="flex w-full flex-col gap-6 overflow-y-auto">
					<div className="flex flex-col gap-6">
						{/* List of user's templates */}
						{(templates?.templates.length ?? 0) > 0 ? (
							<div className="flex w-full flex-col gap-4">
								<Text className="px-3 text-gray-200">Your templates</Text>
								<div className="flex flex-col gap-1">
									{templates?.templates.map((template, i) => (
										<div
											key={i}
											className={cx(
												"flex w-full cursor-pointer items-center gap-3 rounded-lg px-3 py-2 transition-all hover:bg-gray-300",
												template.uuid === selectedTemplate?.uuid &&
													"bg-gray-300",
											)}
											onClick={() => {
												const built =
													buildDefaultTemplateFromTemplate(template);

												if (isMobile) {
													setDefaultTemplate(built);
												} else {
													setTemplate(built);
												}
											}}
										>
											<Text>{template.emoji ?? "☀️"}</Text>{" "}
											<Text variant="bold/lg">{template.name}</Text>
										</div>
									))}
								</div>
							</div>
						) : null}

						{/* List of default templates */}
						{defaultTemplates?.sections?.map((section) => (
							<div key={section.section} className="flex w-full flex-col gap-4">
								<Text className="px-3 text-gray-200">{section.section}</Text>
								<div className="flex flex-col gap-1">
									{section.templates?.map((template, i) => (
										<div
											key={i}
											className={cx(
												"flex w-full cursor-pointer items-center gap-3 rounded-lg px-3 py-2 transition-all hover:bg-gray-300",
												template.uuid === selectedTemplate?.uuid &&
													"bg-gray-300",
											)}
											onClick={() => {
												if (isMobile) {
													setDefaultTemplate(template);
												} else {
													setTemplate(template);
												}
											}}
										>
											<Text>{template.emoji}</Text>{" "}
											<Text variant="bold/lg">{template.name}</Text>
										</div>
									))}
								</div>
							</div>
						))}
					</div>
				</div>
			</div>

			{/* Template preview */}
			<div className="relative hidden h-full w-full flex-col gap-6 bg-gray-600 p-8 md:flex">
				<Close
					className="absolute right-6 top-6 h-4 w-4 cursor-pointer text-gray-200 transition-all hover:text-gray-100"
					role="button"
					aria-label="Close"
					tabIndex={0}
					onClick={() => setIsOpen(false)}
				/>
				<TemplatePreview
					selectedTemplate={selectedTemplate}
					subjectEditor={subjectEditor}
					bodyEditor={bodyEditor}
				/>

				{selectedTemplate?.requiresUpgrade ? null : (
					<div className="mt-auto">
						<Button
							full
							size="huge"
							onClick={() => {
								if (selectedTemplate) {
									setDefaultTemplate(selectedTemplate);
								}
							}}
						>
							<span className="flex items-center gap-2">
								{isForAutomaticPitching
									? "Use Template for Automatic Pitching"
									: "Continue"}
							</span>
						</Button>
					</div>
				)}
			</div>
		</div>
	);
};

const TemplatePreview = ({
	selectedTemplate,
	subjectEditor,
	bodyEditor,
}: {
	selectedTemplate?: DefaultTemplate;
	subjectEditor: Editor | null;
	bodyEditor: Editor | null;
}) => {
	const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
	const utils = trpc.useContext();

	if (!selectedTemplate) {
		return null;
	}

	const renderPreviewContent = () => {
		return (
			<>
				{selectedTemplate.subject || selectedTemplate.requiresUpgrade ? (
					<>
						<div className="flex flex-col gap-3">
							<Text className="text-gray-200">Subject</Text>
							<EditorContent editor={subjectEditor} />
						</div>
						<div className="border-b border-gray-300" />
					</>
				) : null}
				<div className="flex flex-col gap-3 overflow-y-auto">
					<Text className="text-gray-200">Body</Text>
					<EditorContent editor={bodyEditor} />
				</div>
			</>
		);
	};

	if (selectedTemplate.requiresUpgrade) {
		return (
			<>
				<div className="relative h-full w-full">
					{renderPreviewContent()}
					<div className="absolute left-0 top-0 -ml-1 mr-1 flex h-full w-[calc(100%+0.25rem)] items-center justify-center backdrop-blur-sm">
						<Button size="huge" onClick={() => setIsUpgradeModalOpen(true)}>
							Upgrade to unlock
						</Button>
					</div>
				</div>

				<UpgradeModal
					isOpen={isUpgradeModalOpen}
					setIsOpen={(isOpen) => {
						if (!isOpen) {
							utils.template.getDefault.invalidate();
						}

						setIsUpgradeModalOpen(isOpen);
					}}
					feature="template"
				/>
			</>
		);
	}

	return renderPreviewContent();
};
